<template>
  <div class="contract-detail">
    <app-detail :opt="opt"></app-detail>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      opt: {
        title: "公寓信息",
        form: [
          {
            label: "房源名称",
            key: "apartmentStr",
            isCol50: true
          },
          {
            label: "位置",
            key: "addressStr",
            isCol50: true
          },
          {
            label: "房型名称",
            key: "typeIdStr",
            isCol50: true
          },
          {
            label: "租赁周期",
            key: "dateStr",
            isCol50: true
          },
          {
            label: "面积",
            key: "areaStr",
            isCol50: true
          },
          {
            label: "配套",
            key: "labels",
            isCol50: true
          },
          {
            label: "楼栋",
            key: "buildingNo",
            isCol50: true
          },
          {
            label: "单元",
            key: "unitNo",
            isCol50: true
          },
          {
            label: "房号",
            key: "roomNo",
            isCol50: true
          },
          {
            label: "楼层",
            key: "floor",
            isCol50: true
          },
          {
            label: "简介",
            key: "description",
            type: "textarea"
          },

          {
            label: "房型相册",
            key: "imgs",
            type: "img-list",
            opt: {
              list: [
                {
                  src: ""
                }
              ]
            }
          }
        ],
        get(opt) {
          let dto = {
            id: _this.$route.query.id || ""
          };
          _this.post("landlord-service/room/queryRoom", dto).then(data => {
            data.addressStr = data.apartmentReq.address;
            data.areaStr = data.roomTypeResp.area;
            // 设置租赁周期
            data.dateStr = _this.leasecycle;

            // 获取房型图片
            let imgList = [];
            for (var key in data.roomTypeResp) {
              if (key.indexOf("pic") != -1) {
                imgList.push({
                  src: data.roomTypeResp[key]
                });
              }
            }
            data.imgs = imgList;

            // 获取房型和配套
            _this.getTypeIdList().then(() => {
              _this.getFacilitiesList().then(() => {
                // 设置房型
                _this.rooTypeList.forEach(item => {
                  if (data.roomTypeResp.typeId == item.id) {
                    data.typeIdStr = item.name;
                  }
                });

                // 设置配套
                let facilStr = [];
                _this.facilities.forEach(item => {
                  // facilities
                  if (data.roomTypeResp.facilities.indexOf(item.id) != -1) {
                    facilStr.push(item.name);
                  }
                });
                data.labels = facilStr.join(",");

                // 设置房源
                _this.getApartmentList().then(() => {
                  _this.apartmentList.forEach(item => {
                    if (item.id == data.apartmentId) {
                      data.apartmentStr = item.name;
                    }
                  });
                  opt.cb(data);
                });
              });
            });
          });
        }
      }
    };
  },
  methods: {
    // 获取房型
    getTypeIdList() {
      return this.post("landlord-service/base/getRoomTypeAll", {}).then(res => {
        this.rooTypeList = res;
      });
    },

    // 获取房型配套
    getFacilitiesList() {
      return this.post("landlord-service/base/getFacilitiesAll", {}).then(
        res => {
          this.opt.form.forEach(data => {
            this.facilities = res;
          });
        }
      );
    },
    //获取房源列表
    getApartmentList() {
      var dto = {
        pageNumber: "1",
        pageSize: "99999"
      };
      return this.post(
        "landlord-service/apartment/queryPagesApartment",
        dto
      ).then(res => {
        this.apartmentList = res;
      });
    }
  }
};
</script>
<style lang="scss">
.contract-detail__box {
  margin: 0 auto;
  max-width: 900px;
}
</style>
